<template>
  <div class="">
    <div class="customer-white">
      <skeleton-list :row="8" v-if="listLoading" />
      <el-table :data="items" v-else>
        <el-table-column prop="date" label="DATE CRéATION" width="180">
          <template slot-scope="{ row }">
            <span>{{ row.created_at }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoice_no" label="N° FACTURE" width="180">
          <template slot-scope="{ row }">
            <router-link :to="`/sales/credit-note/` + row._id">
              <span class="text-blue-500">{{ row.number }}</span>
            </router-link>
          </template>
        </el-table-column>
        <!--         <el-table-column prop="date" label="DATE FACTURE" width="130">
            <template slot-scope="{ row }">
              <span>{{ row.date }}</span>
            </template>
          </el-table-column> -->
        <el-table-column prop="Écheance" label="ÉCHEANCE" width="150">
          <template slot-scope="{ row }">
            <div>
              <span v-if="row.due_date !== null">{{ row.due_date }}</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="STATUT" width="160">
          <template slot-scope="{ row }">
            <Status :name="row.status" />
          </template>
        </el-table-column>

        <el-table-column prop="contact_name" label="CLIENT" min-width="200">
          <template slot-scope="{ row }">
            <div class="flex flex-col">
              <div class="flex flex-col">
                <span class="uppercase">{{ row.contact_name }} </span>
                <span class="text-gray-500 text-xs"
                  >{{ row.contact_phone }}/{{ row.contact_street }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="selling_price"
          label="TOTAL "
          align="right"
          width="140"
        >
          <template slot-scope="{ row }">
            <span>{{ row.total | moneyFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="selling_price"
          label="AVANCE "
          align="right"
          width="140"
        >
          <template slot-scope="{ row }">
            <span>{{ row.amount_to_pay | moneyFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="total_wt"
          label="À REGLER "
          width="160"
          align="right"
        >
          <template slot-scope="{ row }">
            <span>{{ (row.total - row.amount_to_pay) | moneyFilter }} </span>
            <span>{{ row.currency }}</span>
          </template>
        </el-table-column>
        <el-table-column width="160" label="Actions" align="right">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.status === 'OPEND'"
              size="mini"
              type="primary"
              icon="el-icon-money"
              @click="handlePaymentInvoice(row)"
              circle
            >
            </el-button>
            <el-button
              @click="exportInvoicePDF(row)"
              size="mini"
              icon="el-icon-printer"
              :loading="
                row._id === exportInvoiceId ? loandExportInvoice : false
              "
              circle
            >
            </el-button>
            <el-button
              @click="handleView(row)"
              size="mini"
              type="info"
              icon="el-icon-view"
              circle
            >
            </el-button>

            <el-dropdown
              style="margin-left: 10px"
              @command="handleCommand"
              trigger="click"
            >
              <el-button size="mini" icon="el-icon-menu" circle></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ get: row }">
                  <i class="el-icon-tickets"></i>
                  Afficher
                </el-dropdown-item>
                <el-dropdown-item :command="{ copy: row }">
                  <i class="el-icon-document-copy"></i>
                  Dupliquer
                </el-dropdown-item>
                <el-dropdown-item
                  :disabled="row.status !== 'DRAFT'"
                  :command="{ update: row }"
                >
                  <i class="el-icon-edit"></i>
                  Modifier
                </el-dropdown-item>
                <el-dropdown-item
                  :disabled="row.status !== 'OPEND' && row.status !== 'DRAFT'"
                  :command="{ cancel: row }"
                >
                  <i class="el-icon-delete"></i>
                  Annuler
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="fetchInvoices()"
      />
    </div>
    <!-- List Payment en Bl -->
    <el-dialog
      :title="`REGLÈMENT FACTURE n°: ${invoicePay.number}`"
      :visible.sync="dialogPaymentInvoice"
      width="35%"
      top="4vh"
    >
      <el-form
        :model="paymentInvoice"
        :rules="rules"
        ref="paymentInvoiceForm"
        :label-position="labelPosition"
      >
        <div class="flex">
          <el-form-item
            style="width: 60%"
            class="mr-2"
            prop="payment_no"
            label="Numéro "
          >
            <el-input
              class="w-full"
              v-model="paymentInvoice.payment_no"
              :readonly="true"
            >
            </el-input>
          </el-form-item>

          <el-form-item style="width: 30%" prop="payment_date" label="Date">
            <el-date-picker
              class="100%"
              v-model="paymentInvoice.payment_date"
              type="date"
              placeholder="jj-mm-aaaa"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="my-5 text-blue">
          Selectionner le compte et entrer le montant correspondant
        </div>
        <div
          class="item flex items-center"
          v-for="(item, index) in paymentInvoice.accounts"
          :key="item.account_id"
        >
          <i
            v-if="paymentInvoice.accounts.length > 1"
            @click="handleDeletePaymentItem(index)"
            style="width: 5%"
            class="el-icon-circle-close text-gray-500 text-2xl cursor-pointer"
          ></i>
          <el-form-item
            class="mr-2"
            style="width: 70%"
            :prop="`accounts.${index}.account_number`"
            :rules="{
              required: true,
              message: 'Le compte est obligatoire',
              trigger: 'change',
            }"
          >
            <el-select
              v-model="item.account_number"
              prop="account_number"
              class="w-full"
              @change="handleSelectAccount(item)"
            >
              <el-option
                v-for="item in accounts"
                :key="item.account_id"
                :label="`${item.account_name}`"
                :value="item.numero"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            style="width: 30%"
            :prop="`accounts.${index}.amount`"
            :rules="{
              required: true,
              message: 'Le montant est obligatoire',
              trigger: 'blur',
            }"
          >
            <el-input-number
              style="width: 100%"
              :controls="false"
              v-model="item.amount"
              :min="0.0"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="flex justify-end">
          <el-button
            v-if="paymentInvoice.accounts.length < 3"
            type="text"
            @click="handleAccount"
            >Ajouter un compte</el-button
          >
        </div>
        <el-form-item label="Note">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 3 }"
            placeholder="Entrez quelque chose"
            v-model="paymentInvoice.note"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button @click="dialogPaymentInvoice = false">Annuler</el-button>
        <el-button
          type="primary"
          :disabled="paymentInvoiceLoading"
          :loading="paymentInvoiceLoading"
          @click="createPaymentInvoice('paymentInvoiceForm')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>

    <!-- View PDF -->
    <PDFView :url="urlPDF" :visible="dialogViewPDF" @close="handleClose" />
  </div>
</template>

<script>
import { fetchAccounts } from "@/api/account";
import {
  getAllDocument,
  getNextPaymentNO,
  newPaymentInvoice,
  fetchStatistcs,
  cancelDocument,
  copyDocument,
} from "@/api/sales2";

import {
  exportInvoiceListPDF,
  exportCREDITNOTEPDF,
  exportExcelAllDocument,
} from "@/api/export";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate, formatDate, getDayRetard } from "@/utils";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import PDFView from "@/components/PDF/view";

export default {
  name: "Article",
  components: { Pagination, Status, SkeletonList, PDFView },
  filters: {
    filterTime: parseDate,
    dayRetard: getDayRetard,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      typeDoc: "CREDIT_NOTE",
      urlPDF: null,
      dialogViewPDF: false,
      invoicePay: {
        number: undefined,
        restToPay: 0,
        _id: undefined,
        customerId: undefined,
      },
      loandExportInvoice: false,
      exportInvoiceId: "",
      cancelInvoiceId: "",
      loadingCanceledInvoice: false,
      loadingStat: false,
      loadingPDF: false,
      invoices: [],
      categories: [],
      activeName: "ALL",
      listLoading: false,
      labelPosition: "top",
      dialogPaymentInvoice: false,
      paymentInvoiceLoading: false,
      file: undefined,
      statistque: {
        count_opend: 0,
        count_today: 0,
        count_unpaid: 0,
        count_waiting: 0,
        total_amount_opend: 0,
        total_amount_today: 0,
        total_amount_unpaid: 0,
      },
      items: [],
      exportLoading: false,
      paymentInvoice: {
        contact_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        accounts: [],
        type: "REFUND_CUSTOMER",
        invoices: [],
      },
      accounts: [],
      rules: {
        payment_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        payment_no: [
          {
            required: true,
            message: "La numéro paiement est obligatoire",
            trigger: "bluer",
          },
        ],
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        deadlineDate: undefined,
        end: undefined,
        isUnpaid: undefined,
        contactId: this.$route.params && this.$route.params.id,
        invoiceNo: undefined,
        status: undefined,
        type: "CREDIT_NOTE",
      },
    };
  },
  computed: {
    totalPay: function () {
      return this.paymentInvoice.accounts.reduce(
        (total, item) => total + item.amount,
        0
      );
    },
  },
  mounted() {
    this.fetchInvoices();
    this.fetchStat();
    this.getAccounts();
  },
  methods: {
    async fetchInvoices() {
      this.listLoading = true;

      if (this.listQuery.contactId === "") {
        this.listQuery.contactId = undefined;
      }

      await getAllDocument(this.listQuery, this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;

            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleTab(obj) {
      const name = obj.name;
      this.listQuery.isUnpaid = undefined;
      this.listQuery.isUnpaid = false;
      this.listQuery.page = 1;
      switch (name) {
        case "OPEND":
          this.listQuery.status = "OPEND";
          break;

        case "PAID":
          this.listQuery.status = "PAID";
          break;

        case "CANCELED":
          this.listQuery.status = "CANCELED";
          break;

        case "DRAFT":
          this.listQuery.status = "DRAFT";
          break;
        case "UNPAID":
          this.listQuery.status = "OPEND";
          this.listQuery.isUnpaid = true;
          break;
        default:
          this.listQuery.status = undefined;
      }
      this.fetchInvoices();
    },
    async fetchStat() {
      this.loadingStat = true;
      await fetchStatistcs(this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            this.statistque = Object.assign({}, res.data);
            this.loadingStat = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleCustomer(id) {
      this.listQuery.contactId = id;
      this.fetchInvoices();
    },

    exportInvoiceList() {
      this.exportLoading = true;
      exportInvoiceListPDF(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "factures-" +
              this.listQuery.start +
              "-" +
              this.listQuery.end +
              this.file
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    exportExcelADocuments() {
      this.exportLoading = true;
      exportExcelAllDocument(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "factures.xls"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleExport(e) {
      if (
        this.listQuery.start === undefined ||
        this.listQuery.end === undefined
      ) {
        this.$message({
          showClose: true,
          message: "Veuillez de selectionner l'interval de date.",
          type: "error",
        });
      } else {
        switch (e) {
          case "PDF":
            this.exportInvoiceList();
            break;
          case "EXCEL":
            this.exportExcelADocuments();
            break;
        }
      }
    },
    handleSelectAccount(item) {
      const accountExistList = this.paymentInvoice.accounts.filter(
        (el) => el.account_number === item.account_number
      ).length;

      if (accountExistList > 1) {
        item.account_number = "";
        this.$message({
          message: "Veuillez selectionner un autre compte",
          type: "warning",
        });
      } else {
        const account = this.accounts.find(
          (el) => el.numero === item.account_number
        );
        console.log(account);
        item.account_name = account.account_name;
      }
    },
    handleDeletePaymentItem(index) {
      this.paymentInvoice.accounts.splice(index, 1);
    },
    handleAccount() {
      this.paymentInvoice.accounts.push({
        account_number: "",
        account_name: "",
        amount: 0,
      });
    },
    handlePaymentInvoice(e) {
      this.resetPaymentInvoice();
      this.invoicePay.number = e.number;
      this.invoicePay.customerId = e.contact_id;
      this.invoicePay._id = e._id;
      this.invoicePay.restToPay = e.total - e.amount_to_pay;
      const accountDefault = this.accounts.find(
        (el) => el.has_primary === true
      );

      this.paymentInvoice.accounts.push({
        account_number: accountDefault.numero,
        account_name: accountDefault.account_name,
        amount: this.invoicePay.restToPay,
      });

      this.getNextPaymentNo();
      const accountContactIndex = this.accounts.findIndex(
        (el) => el.type_account === "CONTACT_CREDIT"
      );
      if (accountContactIndex > -1) {
        this.accounts.splice(accountContactIndex, 1);
      }
      if (e.account_credit_number != null && e.account_credit_balance > 0) {
        this.accounts.unshift({
          numero: e.account_credit_number,
          account_name: e.account_credit_title,
          type_account: "CONTACT_CREDIT",
          amount: 0,
        });
      }
      this.dialogPaymentInvoice = true;
      this.$nextTick(() => {
        this.$refs["paymentInvoiceForm"].clearValidate();
      });
    },
    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getNextPaymentNo() {
      await getNextPaymentNO()
        .then((res) => {
          this.paymentInvoice.payment_no = res.data.number;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetPaymentInvoice() {
      this.paymentInvoice = {
        contact_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        accounts: [],
        type: "REFUND_CUSTOMER",
        invoices: [],
      };
      this.invoicePay = {
        number: undefined,
        restToPay: 0,
        _id: undefined,
        customerId: undefined,
      };
    },
    createPaymentInvoice(paymentInvoiceForm) {
      this.$refs[paymentInvoiceForm].validate((valid) => {
        if (valid) {
          if (this.totalPay > this.invoicePay.restToPay) {
            this.$message({
              showClose: true,
              message: "Les montant restant à payer est different total saisi",
              type: "error",
            });
          } else {
            this.paymentInvoiceLoading = true;
            this.paymentInvoice.invoices = [];
            this.paymentInvoice.invoices.push({
              _id: this.invoicePay._id,
              amount: this.totalPay,
            });

            this.paymentInvoice.contact_id = this.invoicePay.customerId;

            newPaymentInvoice(this.paymentInvoice, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  let invoiceItem = this.items.find(
                    (el) => el._id === this.invoicePay._id
                  );
                  invoiceItem.amount_to_pay += this.totalPay;

                  if (this.invoicePay.restToPay === this.totalPay) {
                    invoiceItem.status = "PAID";
                  }

                  this.paymentInvoiceLoading = false;
                  this.dialogPaymentInvoice = false;
                  this.$message({
                    showClose: true,
                    message: "Le paiement enregistré.",
                    type: "success",
                  });
                  //  this.$router.push(`/purchase/invoice/${res.data}`);
                }, 1.5 * 1000);
              })
              .catch((err) => {
                this.paymentInvoice.invoices;

                this.$message({
                  showClose: true,
                  message: err.data.message,
                  type: "error",
                });
                this.paymentInvoiceLoading = false;
              });
          }
        }
      });
    },

    handleCanceledInvoice(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "Note de crédit N° " + e.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler la note de crédit ? "),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelDocument(e._id, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  let invoiceItem = this.items.find((el) => el._id === e._id);
                  invoiceItem.status = "CANCELED";
                  this.fetchStat();
                  this.$message({
                    type: "success",
                    message: "La note de crédit a été annulée",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCopyInvoice(e) {
      const h = this.$createElement;
      this.$msgbox({
        title: "Note de crédit N° " + e.number,
        message: h("div", null, [
          h(
            "span",
            null,
            " Voulez vous vraiment dupliquer la note de crédit ? "
          ),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            copyDocument(e._id, this.typeDoc)
              .then((res) => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.$message({
                    message: "La note de crédit a été dupliqué",
                    type: "success",
                  });

                  this.$router.push(`/sales/credit-note/${res.data._id}`);
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCommand(e) {
      if (e.get) {
        this.$router.push(`/sales/credit-note/${e.get._id}`);
      }
      if (e.update) {
        this.$router.push(`/sales/credit-note/update/${e.update._id}`);
      }
      if (e.cancel) {
        this.handleCanceledInvoice(e.cancel);
      }
      if (e.copy) {
        this.handleCopyInvoice(e.copy);
      }
    },
    exportInvoicePDF(e) {
      this.exportInvoiceId = e._id;
      exportCREDITNOTEPDF(e._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleView(e) {
      this.dialogViewPDF = true;
      exportCREDITNOTEPDF(e._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "application/pdf" })
          );
          this.urlPDF = url;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleClose() {
      this.dialogViewPDF = false;
    },
  },
};
</script>

<style></style>
