<template>
  <div>
    <div class="customer-white">
      <skeleton-list :row="6" v-if="listLoading" class="mb-6" />
      <el-table
        v-else
        :data="items"
        v-loading="listLoading"
        style="width: 100%"
        class="mt-2"
      >
        <el-table-column label="DATE Création" width="150">
          <template slot-scope="{ row }">
            <span>{{ row.created_at }}</span>
          </template>
        </el-table-column>
        <el-table-column label="N° COMMANDE" width="150">
          <template slot-scope="{ row }">
            <router-link :to="`/sales/orders/` + row._id">
              <span class="text-blue-500">{{ row.number }}</span>
            </router-link>
          </template>
        </el-table-column>

        <el-table-column label="D.ÉCHEANCE" width="150">
          <template slot-scope="{ row }">
            <div class="flex flex-col">
              <span>{{ row.due_date }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" label="RÉFERENCE">
          <template slot-scope="{ row }">
            <span>{{ row.reference }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_active"
          align="center"
          label="STATUT"
          width="140"
        >
          <template slot-scope="{ row }">
            <!-- <div
              v-if="row.status === 'CLOSED'"
              class="bg-green-100 border mx-2 border-green-400 text-green-400 text-xs text-center rounded-md"
            >
              TERMINÉE
            </div>
            <div
              v-if="row.status === 'OPEND'"
              class="bg-blue-100 border border-blue-400 text-blue-400 text-xs text-center rounded-md"
            >
              OUVERTE
            </div>

            <div
              v-if="row.status === 'CANCELED'"
              class="bg-red-100 mx-2 border border-red-400 text-red-400 text-xs text-center rounded-md"
            >
              ANNULÉE
            </div>
            <div
              v-if="row.status === 'DRAFT'"
              class="bg-yellow-100 mx-2 border border-yellow-400 text-yellow-400 text-xs text-center rounded-md"
            >
              BROUILLON
            </div> -->
            <Status :name="row.status" />
          </template>
        </el-table-column>

        <el-table-column
          prop="total_ht"
          label="TOTAL HT"
          align="right"
          min-width="190"
        >
          <template slot-scope="{ row }">
            <span>{{ row.sub_total | moneyFilter }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="total_all_taxe"
          label="TOTAL TTC"
          align="right"
          width="190"
        >
          <template slot-scope="{ row }">
            <span>{{ row.total | moneyFilter }} {{user.currency_code}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="fetchOrders()"
      />
    </div>
  </div>
</template>

<script>
import { getAllDocument } from "@/api/sales2";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";

export default {
  name: "Order",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      orders: [],

      listLoading: false,

      items: [],
      activeName: "ALL",
      typeDoc: "",
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        deadlineDate: undefined,
        end: undefined,
        contactId: this.$route.params && this.$route.params.id,
        status: undefined,
      },
    };
  },

  mounted() {
    this.fetchOrders();
  },
    computed :{
        ...mapGetters(["user"]),
    },
  methods: {
    async fetchOrders() {
      this.listLoading = true;
      await getAllDocument(this.listQuery, "SALE_ORDER")
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;

            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
