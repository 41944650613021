<template>
  <div class="flex flex-col font-Inter mb-3">
    <skeleton-list :row="6" v-if="listLoading" class="mb-6" />
    <el-table :data="items" v-else>
      <el-table-column label="#" width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="date" label="DATE CRéation" width="150">
        <template slot-scope="{ row }">
          <span>{{ row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column label="N° DEVIS" width="140">
        <template slot-scope="{ row }">
          <router-link :to="`quote/` + row._id">
            <span class="text-blue-500">{{ row.number }}</span>
          </router-link>
        </template>
      </el-table-column>

      <el-table-column prop="Écheance" label="ÉCHEANCE" width="140">
        <template slot-scope="{ row }">
          <div class="flex flex-row items-center justify-between">
            <span>{{ row.due_date }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="left" label="STATUT" width="180">
        <template slot-scope="{ row }">
          <!-- <div
              v-if="row.status === 'ACCEPTED_CLOSE'"
              class="bg-green-100 px-2 border border-green-400 text-green-400 text-xs text-center rounded-md"
            >
              ACCEPTÉ
            </div>
            <div
              v-if="row.status === 'OPEND'"
              class="bg-blue-100 border border-blue-400 text-blue-400 text-xs text-center rounded-md"
            >
              EN COURS
            </div>

            <div
              v-if="row.status === 'CANCELED'"
              class="bg-red-100 border border-red-400 text-red-400 text-xs text-center rounded-md"
            >
              ANNULÉ
            </div>
            <div
              v-if="row.status === 'DRAFT'"
              class="bg-yellow-100 border border-yellow-400 text-yellow-400 text-xs text-center rounded-md"
            >
              BROUILLON
            </div> -->
          <Status :name="row.status" />
        </template>
      </el-table-column>

      <el-table-column
        prop="selling_price"
        label="TOTAL HT "
        align="right"
        min-width="120"
      >
        <template slot-scope="{ row }">
          <span>{{ row.sub_total | moneyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="selling_price"
        label="TOTAL TTC"
        align="right"
        width="120"
      >
        <template slot-scope="{ row }">
          <span>{{ row.total | moneyFilter }} {{row.currency}}</span>
        </template>
      </el-table-column>
      <el-table-column width="250" align="right">
        <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            content="Accepter et transformer"
            placement="bottom"
          >
            <el-button
              v-if="row.status === 'OPEND'"
              size="mini"
              type="primary"
              icon="el-icon-document-checked"
              circle
              @click="handleTansformQuote(row._id)"
            >
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Imprimer"
            placement="bottom"
          >
            <el-button
              @click="exportEstimatePDF(row)"
              size="mini"
              type="info"
              icon="el-icon-printer"
              :loading="row._id === exportQuoteId ? loandExportQuote : false"
              circle
            >
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Dupliquer"
            placement="bottom"
          >
            <el-button
              size="mini"
              icon="el-icon-document-copy"
              circle
              :loading="row._id === copyQuoteId ? loadingQuoate : false"
              @click="handleCopyestimate(row._id)"
            >
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Annuler"
            placement="bottom"
          >
            <el-button
              v-if="row.status === 'OPEND' || row.status === 'DRAFT'"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleCanceledQuote(row._id)"
              :loading="row._id === cancelQuoteId ? cancelLoading : false"
              circle
            >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="getData()"
      />
    </div>

    <transform-quote
      :number="quoteTransfId"
      :dialogVisible="dialogTransVisible"
      @closeDialog="handleCloseTransfDialog"
    />
  </div>
</template>

<script>
import {
  fetchStatistcs,
  getAllDocument,
  cancelDocument,
  copyDocument,
} from "@/api/sales2";
import { exportQuoteListPDF, exportQuotePDF } from "@/api/export";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import TransformQuote from "@/components/TransformQuote";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";

export default {
  name: "Quote",
  components: {
    Pagination,
    Status,
    "transform-quote": TransformQuote,
    SkeletonList,
  },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      listLoading: false,
      exportLoading: false,
      items: [],
      typeDoc: "QUOTE",
      countQuoteWait: 0,
      activeName: "ALL",
      exportQuoteId: "",
      loandExportQuote: false,
      copyQuoteId: "",
      loadingQuoate: false,
      cancelLoading: false,
      cancelQuoteId: "",
      totalItem: 0,
      dialogTransVisible: false,
      quoteTransfId: "",
      file: undefined,
      statistque: {
        count_opend: 0,
        count_today: 0,
        count_unpaid: 0,
        count_waiting: 0,
        total_amount_opend: 0,
        total_amount_today: 0,
        total_amount_unpaid: 0,
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        end: undefined,
        quoteNo: undefined,
        status: undefined,
        contactId: this.$route.params && this.$route.params.id,
        typeFile: undefined,
      },
    };
  },
  mounted() {
    this.getData();
    this.fetchStat();
  },
  methods: {
    async getData() {
      this.listLoading = true;

      if (this.listQuery.quoteNo === "") {
        this.listQuery.quoteNo = undefined;
      }

      await getAllDocument(this.listQuery, this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleTab(obj) {
      const name = obj.name;

      switch (name) {
        case "OPEND":
          this.listQuery.status = "OPEND";
          break;

        case "ACCEPTED":
          this.listQuery.status = "ACCEPTED";
          break;

        case "ACCEPTED_CLOSE":
          this.listQuery.status = "ACCEPTED_CLOSE";
          break;

        case "REFUSED":
          this.listQuery.status = "REFUSED";
          break;

        default:
          this.listQuery.status = undefined;
      }
      this.getData();
    },
    handleCustomer(id) {
      this.listQuery.contactId = id;
      this.getData();
    },
    exportQuoteListPDF() {
      this.exportLoading = true;
      exportQuoteListPDF(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute(
            "download",
            "devis-" +
              this.listQuery.start +
              "-" +
              this.listQuery.end +
              this.file
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
          //console.log("hello");
        });
    },
    exportEstimatePDF(row) {
      this.exportQuoteId = row._id;
      this.loandExportQuote = true;

      exportQuotePDF(row._id)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", "DEVIS N°" + row.number + ".pdf"); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.loandExportQuote = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCopyestimate(id) {
      this.copyQuoteId = id;
      this.loadingQuoate = true;
      copyDocument(id, this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            this.$message({
              message: "Le devis été copié",
              type: "success",
            });
            this.loadingQuoate = false;
            this.$router.push(`/sales/quote/${res.data._id}`);
          }, 1000);
        })
        .catch(() => {});
    },
    handleCanceledQuote(id) {
      this.cancelLoading = true;
      this.cancelQuoteId = id;
      cancelDocument(id, this.typeDoc)
        .then(() => {
          setTimeout(() => {
            let estimate = this.items.find((el) => el._id === id);
            estimate.status = "CANCELED";
            this.cancelLoading = false;
            this.$message({
              message: "Le devis N° " + estimate.number + " a été annulé ",
              type: "success",
            });
          }, 1000);
        })
        .catch(() => {});
    },
    handleExport(e) {
      if (
        this.listQuery.start === undefined ||
        this.listQuery.end === undefined
      ) {
        this.$message({
          showClose: true,
          message: "Merci de selectionner deux dates valide.",
          type: "error",
        });
      } else {
        switch (e) {
          case "PDF":
            this.listQuery.typeFile = "PDF";
            this.file = ".pdf";
            break;
          case "EXCEL":
            this.listQuery.typeFile = "EXCEL";
            this.file = ".xlsx";
        }
        this.exportQuoteListPDF();
      }
    },
    handleCloseTransfDialog(e) {
      this.dialogTransVisible = e;
    },
    handleTansformQuote(e) {
      this.quoteTransfId = e;
      this.dialogTransVisible = true;
    },
    async fetchStat() {
      await fetchStatistcs(this.typeDoc)
        .then((res) => {
          this.statistque = Object.assign({}, res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
