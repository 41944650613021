<template>
  <div>
    <skeleton-list :row="6" v-if="listLoading" class="mb-6" />
    <el-table :data="items" v-else>
      <el-table-column prop="date" label="DATE" width="180">
        <template slot-scope="{ row }">
          <span>{{ row.created_at }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payment_no" label="N°PAIEMENT" width="150">
        <template slot-scope="{ row }">
          <router-link :to="`payments/${row.payment_no}`">
            <span class="text-blue-500">{{ row.payment_no }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="payment_mode" label="METHODE" width="160">
      </el-table-column>
      <el-table-column label="STATUT" width="180">
        <template slot-scope="{ row }">
          <Status :name="row.status" />
        </template>
      </el-table-column>
      <!-- 
        <el-table-column prop="customer" label="CLIENTS">
          <template slot-scope="{ row }">
            <div class="flex flex-col">
              <span class="">{{ row.contact_name }}</span>
              <span class="text-gray-500 text-xs"
                >{{ row.contact_street }} {{ row.contact_phone }}</span
              >
            </div>
          </template>
        </el-table-column> -->

      <el-table-column prop="note" label="Note">
        <template slot-scope="{ row }">
          <div class="flex flex-col">
            <span class="">{{ row.note }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="amount" label="MONTANT " align="right" width="170">
        <template slot-scope="{ row }">
          <span>{{ row.total | moneyFilter }} {{user.currency_code}} </span>
        </template>
      </el-table-column>

      <el-table-column width="120" align="right">
        <template slot-scope="{ row }">
          <el-button
            :loading="numberPrinte === row.payment_no ? loadingPrint : false"
            size="mini"
            type="info"
            icon="el-icon-printer"
            circle
            @click="exportRecuPDF(row.payment_no)"
          >
          </el-button>
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            v-if="row.status === 'PAID'"
            @click="handleAnnuler(row.payment_no)"
            circle
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="fetchData()"
      />
    </div>

    <CancelPayment
      :number="numberSelecte"
      :visible="dialogVisible"
      @handleNo="handleCancel"
      @handleConfirme="handleYes"
    />
  </div>
</template>

<script>
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import { fetchPaymentInvoice } from "@/api/sales2";
import Pagination from "@/components/Pagination";
import { exportRecuPDF } from "@/api/export";
import CancelPayment from "@/components/CancelPayment";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";
export default {
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  components: { Pagination, CancelPayment, Status, SkeletonList },

  data() {
    return {
      items: [],
      listLoading: false,
      dialogVisible: false,
      numberSelecte: "",
      numberPrinte: "",

      loadingPrint: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 10,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        end: undefined,
        contactId: this.$route.params && this.$route.params.id,
      },
    };
  },
    computed :{
        ...mapGetters(["user"]),
    },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      await fetchPaymentInvoice(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            console.log(this.items);
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportRecuPDF(e) {
      this.loadingPrint = true;
      this.numberPrinte = e;
      exportRecuPDF(this.numberPrinte)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", "recu-" + this.numberPrinte + ".pdf"); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.loadingPrint = false;
          }, 1000);
        })
        .catch((err) => {
          this.loadingPrint = false;
          console.log(err);
          console.log("hello");
        });
    },
    handleCustomer(id) {
      this.listQuery.contactId = id;
      this.fetchData();
    },

    handleAnnuler(e) {
      this.numberSelecte = e;
      this.dialogVisible = true;
      console.log(this.dialogVisible);
    },
    handleCancel(e) {
      this.dialogVisible = e;
    },
    handleYes() {
      console.log("succces");
      this.dialogVisible = false;
      const item = this.items.find(
        (el) => el.payment_no === this.numberSelecte
      );
      item.status = "CANCELED";
    },
  },
};
</script>

<style lang="scss" scoped></style>
