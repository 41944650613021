<template>
  <div>
    <div class="customer-white mt-4">
      <skeleton-list :row="6" v-if="listLoading" class="mb-6" />
      <el-table v-else :data="items">
        <el-table-column prop="date" label="DATE création" width="160">
          <template slot-scope="{ row }">
            <span>{{ row.created_at }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoice_no" label="N° FACTURE" width="140">
          <template slot-scope="{ row }">
            <router-link :to="`/sales/invoice/` + row._id">
              <span class="text-blue-500">{{ row.number }}</span>
            </router-link>
          </template>
        </el-table-column>

        <el-table-column prop="Écheance" label="ÉCHEANCE" width="150">
          <template slot-scope="{ row }">
            <div class="flex flex-col items-center justify-between">
              <span v-if="row.due_date !== null">{{ row.due_date }}</span>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_active"
          align="left"
          label="STATUT"
          width="160"
        >
          <template slot-scope="{ row }">
            <Status :name="row.status" />
          </template>
        </el-table-column>

        <el-table-column
          prop="selling_price"
          label="TOTAL "
          align="right"
          min-width="150"
        >
          <template slot-scope="{ row }">
            <span>{{ row.total | moneyFilter }} {{ row.currency }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="selling_price"
          label="AVANCE "
          align="right"
          width="160"
        >
          <template slot-scope="{ row }">
            <span>{{ row.amount_to_pay | moneyFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="total_wt"
          label="À REGLER "
          width="150"
          align="right"
        >
          <template slot-scope="{ row }">
            <span class=""
              >{{ (row.total - row.amount_to_pay) | moneyFilter }}

            </span>
          </template>
        </el-table-column>
        <el-table-column width="190" label="Actions" align="right">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.status === 'OPEND'"
              size="mini"
              type="primary"
              icon="el-icon-money"
              @click="handlePaymentInvoice(row)"
              circle
            >
            </el-button>
            <el-button
              @click="exportInvoicePDF(row)"
              size="mini"
              type="info"
              icon="el-icon-printer"
              :loading="
                row._id === exportInvoiceId ? loandExportInvoice : false
              "
              circle
            >
            </el-button>
            <!--
              <el-button size="mini" icon="el-icon-message" circle> </el-button>
              -->
            <el-button
              v-if="row.status === 'OPEND'"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleCanceledInvoice(row)"
              :loading="
                row._id === cancelInvoiceId ? loadingCanceledInvoice : false
              "
              circle
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="fetchInvoices()"
      />
    </div>
    <!-- List Payment en Bl -->
    <el-dialog
      :title="`REGLÈMENT FACTURE `"
      :visible.sync="dialogPaymentInvoice"
      width="35%"
      top="4vh"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        :model="paymentInvoice"
        :rules="rules"
        ref="paymentInvoiceForm"
        :label-position="labelPosition"
      >
        <div class="flex">
          <el-form-item
            style="width: 50%"
            class="mr-2"
            prop="payment_no"
            label="Numéro "
          >
            <el-input
              class="w-full"
              v-model="paymentInvoice.payment_no"
              :readonly="true"
            >
            </el-input>
          </el-form-item>

          <el-form-item style="width: 50%" prop="payment_date" label="Date">
            <el-date-picker
              style="width: 100%"
              class="w-full"
              v-model="paymentInvoice.payment_date"
              type="date"
              placeholder="dd mmm. aaaa"
              format="dd MMM. yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="my-5 text-blue">Compte de réception</div>
        <div
          class="item flex items-center"
          v-for="(item, index) in paymentInvoice.accounts"
          :key="item.account_id"
        >
          <i
            v-if="paymentInvoice.accounts.length > 1"
            @click="handleDeletePaymentItem(index)"
            style="width: 5%"
            class="el-icon-circle-close text-gray-500 text-2xl cursor-pointer"
          ></i>
          <el-form-item
            class="mr-2"
            style="width: 70%"
            :prop="`accounts.${index}.account_number`"
            :rules="{
              required: true,
              message: 'Le compte est obligatoire',
              trigger: 'change',
            }"
          >
            <el-select
              v-model="item.account_number"
              prop="account_number"
              class="w-full"
              @change="handleSelectAccount(item)"
            >
              <el-option
                v-for="item in accounts"
                :key="item.account_id"
                :label="`${item.account_name}`"
                :value="item.numero"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            style="width: 30%"
            :prop="`accounts.${index}.amount`"
            :rules="{
              required: true,
              message: 'Le montant est obligatoire',
              trigger: 'blur',
            }"
          >
            <el-input-number
              style="width: 100%"
              :controls="false"
              v-model="item.amount"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="flex justify-end">
          <el-button
            v-if="paymentInvoice.accounts.length < 2"
            type="text"
            @click="handleAccount"
            >Ajouter un compte</el-button
          >
        </div>
        <el-form-item label="Note">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 3 }"
            placeholder="Entrez quelque chose"
            v-model="paymentInvoice.note"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button @click="dialogPaymentInvoice = false">Annuler</el-button>
        <el-button
          type="primary"
          :disabled="paymentInvoiceLoading"
          :loading="paymentInvoiceLoading"
          @click="createPaymentInvoice('paymentInvoiceForm')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fetchAccounts } from "@/api/account";
import {
  getNextPaymentNO,
  newPaymentInvoice,
  getAllDocument,
  cancelDocument,
} from "@/api/sales2";

import { exportInvoicePDF } from "@/api/export";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseDate, formatDate, getDayRetard } from "@/utils";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";

export default {
  name: "FICHE-CONTACT-INVOICE",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseDate,
    dayRetard: getDayRetard,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      typeDoc: "INVOICE",
      invoicePay: {
        number: undefined,
        restToPay: 0,
        _id: undefined,
        customerId: undefined,
      },
      loandExportInvoice: false,
      exportInvoiceId: "",
      cancelInvoiceId: "",
      loadingCanceledInvoice: false,
      invoices: [],
      categories: [],
      activeName: "ALL",
      listLoading: false,
      labelPosition: "top",
      dialogPaymentInvoice: false,
      paymentInvoiceLoading: false,
      file: undefined,

      items: [],
      exportLoading: false,
      paymentInvoice: {
        contact_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        accounts: [],
        type: "PAYMENT_CUSTOMER",
        invoices: [],
      },
      accounts: [],
      rules: {
        payment_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        payment_no: [
          {
            required: true,
            message: "La numéro paiement est obligatoire",
            trigger: "bluer",
          },
        ],

        amount: [
          {
            required: true,
            message: "Le montant est obligatoire",
            trigger: "blur",
          },
        ],
        account_number: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        deadlineDate: undefined,
        end: undefined,
        isUnpaid: undefined,
        contactId: this.$route.params && this.$route.params.id,
        invoiceNo: undefined,
        status: undefined,
        typeFile: undefined,
      },
    };
  },
  mounted() {
    this.fetchInvoices();
    this.getAccounts();
  },
  computed: {
    totalPay: function () {
      return this.paymentInvoice.accounts.reduce(
        (total, item) => total + item.amount,
        0
      );
    },
  },
  methods: {
    async fetchInvoices() {
      this.listLoading = true;
      await getAllDocument(this.listQuery, this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleAccount() {
      this.paymentInvoice.accounts.push({
        account_number: "",
        account_name: "",
        amount: 0,
      });
    },
    handlePaymentInvoice(e) {
      this.resetPaymentInvoice();

      this.invoicePay.number = e.number;
      this.invoicePay.customerId = e.contact_id;
      this.invoicePay._id = e._id;
      this.invoicePay.restToPay = e.total - e.amount_to_pay;
      const accountDefault = this.accounts.find(
        (el) => el.has_primary === true
      );

      this.paymentInvoice.accounts.push({
        account_number: accountDefault.numero,
        account_name: accountDefault.account_name,
        amount: this.invoicePay.restToPay,
      });

      this.getNextPaymentNo();
      const accountContactIndex = this.accounts.findIndex(
        (el) => el.type_account === "CONTACT_CREDIT"
      );

      if (accountContactIndex > -1) {
        this.accounts.splice(accountContactIndex, 1);
      }

      if (e.account_credit_number != null && e.account_credit_balance > 0) {
        this.accounts.unshift({
          numero: e.account_credit_number,
          account_name: e.account_credit_title,
          type_account: "CONTACT_CREDIT",
          amount: 0,
        });
      }
      this.dialogPaymentInvoice = true;
      this.$nextTick(() => {
        this.$refs["paymentInvoiceForm"].clearValidate();
      });
    },
    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
          console.lo;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getNextPaymentNo() {
      await getNextPaymentNO()
        .then((res) => {
          this.paymentInvoice.payment_no = res.data.number;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSelectAccount(item) {
      const accountExistList = this.paymentInvoice.accounts.filter(
        (el) => el.account_number === item.account_number
      ).length;

      if (accountExistList > 1) {
        item.account_number = "";
        this.$message({
          message: "Veuillez selectionner un autre compte",
          type: "warning",
        });
      } else {
        const account = this.accounts.find(
          (el) => el.numero === item.account_number
        );
        item.account_name = account.account_name;
      }
    },
    resetPaymentInvoice() {
      this.paymentInvoice = {
        contact_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        accounts: [],
        type: "PAYMENT_CUSTOMER",
        invoices: [],
      };
      this.invoicePay = {
        number: undefined,
        restToPay: 0,
        _id: undefined,
        customerId: undefined,
      };
    },
    createPaymentInvoice(paymentInvoiceForm) {
      this.$refs[paymentInvoiceForm].validate((valid) => {
        if (valid) {
          // this.paymentBill.accounts.amount = this.paymentBill.amount;
          const exitAmountZero = this.paymentInvoice.accounts.filter(
            (el) => el.amount === 0
          ).length;

          if (this.totalPay > this.invoicePay.restToPay) {
            this.$message({
              showClose: true,
              message: "Les montant restant à payer est different total saisi",
              type: "error",
            });
          } else if (exitAmountZero > 0) {
            this.$message({
              showClose: true,
              message: "Le montant ne peut être égal 0",
              type: "error",
            });
          } else {
            this.paymentInvoiceLoading = true;
            this.paymentInvoice.invoices = [];
            this.paymentInvoice.invoices.push({
              _id: this.invoicePay._id,
              amount: this.totalPay,
            });
            this.paymentInvoice.contact_id = this.invoicePay.customerId;
            newPaymentInvoice(this.paymentInvoice, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  let invoiceItem = this.items.find(
                    (el) => el._id === this.invoicePay._id
                  );
                  invoiceItem.amount_to_pay += this.totalPay;

                  if (this.invoicePay.restToPay === this.totalPay) {
                    invoiceItem.status = "PAID";
                  }
                  console.log(invoiceItem);

                  this.paymentInvoiceLoading = false;
                  this.dialogPaymentInvoice = false;
                  this.$message({
                    showClose: true,
                    message: "Le paiement enregistré.",
                    type: "success",
                  });
                  //  this.$router.push(`/purchase/invoice/${res.data}`);
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  if (err.status === 400) {
                    this.$message({
                      message: "Veuillez remplir les champs obligatoire.",
                      type: "warning",
                    });
                  }

                  if (err.status === 409) {
                    this.$message({
                      message: err.data.message,
                      type: "warning",
                    });
                  }
                  this.paymentInvoiceLoading = false;
                }, 1.5 * 1000);
              });
          }
        }
      });
    },

    exportInvoicePDF(e) {
      this.loandExportInvoice = true;
      this.exportInvoiceId = e._id;
      exportInvoicePDF(e._id)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute("download", "facture-" + e.number + ".pdf"); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.loandExportInvoice = false;
          }, 1000);
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleCanceledInvoice(e) {
      //
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + e.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler la facture  "),
          h("span", { style: "font-weight:bold" }, "N°:" + e.number),
          h("span", { style: "font-weight:bold" }, "   Total = " + e.number),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelDocument(e._id, "INVOICE")
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  let invoiceItem = this.items.find((el) => el._id === e._id);
                  invoiceItem.status = "CANCELED";
                  this.fetchStat();
                  this.$message({
                    type: "success",
                    message: "La facture a été annulée",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
  },
};
</script>

<style></style>
