<template>
  <div>
    <div class="flex justify-end mb-2">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="handleDialogNewTransaction"
        >Transaction</el-button
      >
    </div>
    <skeleton-list :row="6" v-if="listLoading" class="mb-6" />
    <operation-list :items="items" v-else />
    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="getOperationData()"
      />
    </div>
    <new-transaction
      :visible="dialogNewTransaction"
      @close="handleCloseNewTransaction"
      @sucess="handleSuccessNewTransaction"
      :accountFrom="accNumber"
    />
  </div>
</template>

<script>
import OperationList from "@/components/Operation";
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
import { parseTime } from "@/utils";
import { fetchAllOperation } from "@/api/account";
import NewTransaction from "@/components/accountCredit";
import SkeletonList from "@/components/Skeleton/list";

export default {
  name: "CREDIT-CUSTOMER",
  components: { Pagination, OperationList, NewTransaction, SkeletonList },
  filters: {
    parseTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  props: {
    accNumber: String,
  },
  data() {
    return {
      listLoading: false,
      items: [],
      dialogNewTransaction: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        start: undefined,
        end: undefined,
        ref: undefined,
        account: undefined,
        type: undefined,
        raison: undefined,
        sort: "desc",
        sortDir: "createdAt",
      },
    };
  },
  mounted() {
    this.getOperationData();
  },
  methods: {
    async getOperationData() {
      this.listLoading = true;
      this.listQuery.account = this.accNumber;
      await fetchAllOperation(this.listQuery).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleCloseNewTransaction(e) {
      this.dialogNewTransaction = e;
    },
    handleSuccessNewTransaction(e) {
      this.dialogNewTransaction = e;
      this.getOperationData();
      this.$emit("update-statistique", true);
    },
    handleDialogNewTransaction() {
      this.dialogNewTransaction = true;
    },
  },
};
</script>

<style></style>
