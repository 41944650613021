<template>
  <div class="flex flex-col font-Inter px-10">
    <Loading v-if="loadingFull" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div class="mt-1 flex flex-col justify-center items-start">
          <h2
            class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
            style="text-transform: uppercase"
          >
            {{ customer.name_organisation }}
          </h2>
          <div class="text-gray-500 mt-2">
            <span
              >Numéro Client :

              <label for="">{{ customer.contact_no }}</label></span
            >
          </div>
        </div>
        <div>
          <!-- <el-button>Modifier</el-button> -->
          <el-button class="mr-2" icon="el-icon-print"
            >Imprimer relever de compte</el-button
          >

          <el-dropdown @command="handleCommand" trigger="click">
            <el-button type="primary">
              Créer<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <span class="text-blue-400"> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="INV">Facture</el-dropdown-item>
              <el-dropdown-item command="QUOTE">Devis</el-dropdown-item>
              <el-dropdown-item command="ORDER"
                >Bon de commande</el-dropdown-item
              >
              <el-dropdown-item command="PAY">Paiement</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="w-1/2 mr-4">
        <div class="flex">
          <div
            class="bg-white shadow mr-1 flex-1 h-24 mb-2 flex flex-col justify-items-center justify-center px-6"
          >
            <div class="text-xl font-semibold">
              {{ resume.stats?.total_invoice | moneyFilter }} <span class="text-sm">{{user.currency_code}}</span>
            </div>
            <div class="text-yellow-500 text-sm mb-1">
              ({{ resume.stats?.item_invoice }}) Total Facture en cours
            </div>
          </div>
          <div
            class="bg-white shadow ml-1 flex-1 h-24 mb-2 flex flex-col justify-center px-6"
          >
            <div class="text-xl font-semibold">
              {{ resume.stats?.total_quote | moneyFilter }} <span class="text-sm">{{user.currency_code}}</span>
            </div>
            <div class="text-blue-500 text-sm mb-1">
              ( {{ resume.stats?.item_quote }}) Total des devis
            </div>
          </div>
        </div>

        <div class="flex">
          <div
            class="bg-white shadow mr-1 flex-1 h-24 flex flex-col justify-items-center justify-center px-6"
          >
            <div class="text-xl font-semibold">
              {{ resume.stats?.total_order | moneyFilter }} <span class="text-sm">{{user.currency_code}}</span>
            </div>
            <div class="text-red-500 text-sm mb-1">
              ({{ resume.stats?.item_order }}) Total des bons commande
            </div>
          </div>
          <div
            class="bg-white shadow ml-1 flex flex-1 justify-between h-24 px-6"
          >
           <div class="flex flex-col justify-center">
            <div class="text-xl font-semibold">
              {{ resume.stats?.total_creditnote | moneyFilter }}  <span class="text-sm">{{user.currency_code}}</span>
            </div>
            <div class="text-green-500 text-sm mb-1">
              ({{ resume.stats?.item_creditnote }}) Total Note de crédit
            </div>
           </div>
           <div class="flex flex-col justify-center">
            <div class="text-xl font-semibold">
              {{ resume.acc_balance | moneyFilter }} <span class="text-sm">{{user.currency_code}}</span>
            </div>
            <div class="text-pink-500 text-sm mb-1">
             <span v-if="resume.acc_balance >= 0 ">Solde disponible</span> 
             <span v-if="resume.acc_balance < 0 ">Créance disponible</span> 

            </div>
           </div>
          </div>
    
        </div>
      </div>
      <div class="w-1/2 bg-white shadow">
        <div class="px-4 pt-6 flex flex-col">
          <div class="flex justify-start">
            <div class="flex flex-col flex-1">
              <div class="flex mb-3 mr-2">
                <span class="mr-2 font-light w-1/3 text-gray-500"
                  >Téléphone:</span
                >
                <span class="text-base font-medium"> {{ customer.phone }}</span>
              </div>
              <div class="flex mb-3">
                <span class="mr-2 font-light w-1/3 text-gray-500"
                  >Téléphone whatsapp:</span
                >
                <span class="text-base float-left">
                  {{ customer.phone_whatsapp }}</span
                >
              </div>

              <div class="flex mb-3">
                <span class="mr-2 font-light text-gray-500 w-1/3"
                  >Adresse:</span
                >
                <span class="text-base"> {{ customer.street }}</span>
              </div>

              <div class="flex flex-col text-gray-500 mb-3">
                <span class="mr-2 font-light w-1/3">Note:</span>
                <span class="text-base">
                  {{ customer.remark }}
                </span>
              </div>
            </div>
            <div class="flex flex-1 flex-col justify-start">
              <div class="flex mb-3">
                <span class="mr-2 font-light text-gray-500 w-1/3"
                  >Entreprise:</span
                >
                <span class="text-base"> {{ customer.entreprise }}</span>
              </div>
              <div class="flex mb-3">
                <span class="mr-2 font-light text-gray-500 w-1/3">Email:</span>
                <span class="text-base"> {{ customer.email }}</span>
              </div>
              <div class="flex mb-3">
                <span class="mr-2 font-light text-gray-500 w-1/3"
                  >Site web:</span
                >
                <span class="text-base"> {{ customer.website }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row mb-10">
      <div class="flex-1 flex flex-col">
        <div class="px-4 mt-6 bg-white shadow pt-10">
          <el-tabs v-model="activeName">
            <el-tab-pane label="FACTURES" name="INVOICE"
              ><invoice v-if="activeName === 'INVOICE'" />
            </el-tab-pane>
            <el-tab-pane label="BONS DE COMMANDE" name="ORDER"
              ><commande v-if="activeName === 'ORDER'" />
            </el-tab-pane>
            <el-tab-pane label="DEVIS" name="QUOTE"
              ><quote v-if="activeName === 'QUOTE'" />
            </el-tab-pane>
            <el-tab-pane label="NOTES DE CRÉDIT" name="CREDIT_NOTE"
              ><credit-note v-if="activeName === 'CREDIT_NOTE'" />
            </el-tab-pane>

            <el-tab-pane label="REGLÈMENTS REÇU" name="PAYMENT"
              ><payment v-if="activeName === 'PAYMENT'" />
            </el-tab-pane>
            <el-tab-pane
              v-if="resume.acc_number != '' && resume.acc_number != undefined"
              label="COMPTE DE CREDIT "
              name="CREDIT"
              ><credit
                v-if="activeName === 'CREDIT'"
                :accNumber="resume.acc_number"
                @update-statistique="updateStatistique"
              />
            </el-tab-pane>
            <!-- <el-tab-pane label="Bon de livraison"
              ><bon-livraison />
            </el-tab-pane>
            -->
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ItemInfo from "../components/itemInfo.vue";
import Quote from "./components/quote.vue";
//import BonLivraison from "./components/bonlivraison.vue";
import Commande from "./components/commande.vue";
import Invoice from "./components/invoice.vue";
import Payment from "./components/payment.vue";
import Credit from "./components/credit.vue";
import CreditNote from "./components/creditnote";
import Loading from "@/components/Loanding/Full";
//import Avoir from "./components/avoir.vue";
import { getContact, getStats } from "@/api/contact";

import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import {mapGetters} from "vuex";
export default {
  name: "Fiche",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: {
    Quote,
    Invoice,
    Payment,
    Commande,
    Credit,
    CreditNote,
    Loading,
  },
  data() {
    return {
      loadingFull: false,
      resume: {
        acc_balance: 0,
        acc_number: "",
        stats: {}
      },
      activeName: "INVOICE",
      customer: {
        active: true,
        avatar_url: null,
        contact_id: "",
        contact_no: "",
        delivery_address: null,
        email: "",
        first_name: "",
        invoice_address: null,
        is_business: true,
        last_name: "",
        name_organisation: "",
        phone: "",
        phone_whatsapp: null,
        remark: null,
        type: "",
        website: null,
      },
    };
  },
  mounted() {
    this.initData();
  },
    computed :{
        ...mapGetters(["user"]),
    },
  methods: {
    async FETCH_CUSTOMER() {
      const id = this.$route.params && this.$route.params.id;
      await getContact(id).then((res) => {
        this.customer = Object.assign({}, res.data);
      });
    },
    initData() {
      this.loadingFull = true;
      setTimeout(() => {
        this.FETCH_CUSTOMER();
        this.FETCH_BALANCE();
        this.loadingFull = false;
      }, 1.5 * 1000);
    },
    handleClickTab() {},
    async FETCH_BALANCE() {
      const id = this.$route.params && this.$route.params.id;
      await getStats(id).then((res) => {
        this.resume = Object.assign({}, res.data);
        this.sta
      });
    },
    handleCommand(e) {
      let customerInfo = {
        contact_id: this.customer.contact_id,
        phone: this.customer.phone,
        email: this.customer.email,
        first_name: this.customer.first_name,
        last_name: this.customer.last_name,
        street: this.customer.street,
        contact_no: this.customer.contact_no,
        contact_name: this.customer.name_organisation,
        account_credit_balance: this.resume.acc_balance,
        account_credit_number: this.resume.acc_number,
        account_credit_title: "",
      };
      switch (e) {
        case "INV": {
          this.$router.push({
            name: "create-invoice",
            params: { customer: customerInfo },
          });
          break;
        }

        case "QUOTE":
          this.$router.push({
            name: "create-quote",
            params: { customer: customerInfo },
          });
          break;

        case "ORDER":
          this.$router.push({
            name: "create-order",
            params: { customer: customerInfo },
          });
          break;

        case "PAY":
          this.$router.push({
            name: "create-payment-invoice",
            params: { customer: customerInfo },
          });
          break;
      }
    },
    updateStatistique() {
      this.FETCH_BALANCE();
    },
  },
  watch: {
    activeName(val) {
      this.$router.push(`${this.$route.path}?tab=${val}`);
    },
  },
  created() {
    const tab = this.$route.query.tab;

    if (tab) {
      this.activeName = tab;
    }
  },
};
</script>

<style></style>
