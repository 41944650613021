<template>
  <div>
    <el-dialog
      title="Nouvelle transaction"
      width="35%"
      top="4vh"
      :close-on-click-modal="false"
      :visible.sync="visible"
      @open="handleTransaction"
      @close="handleCloseDialog"
      :show-close="false"
    >
      <el-form
        :model="transfert"
        :rules="rule"
        ref="refNewTransaction"
        :label-position="labelPosition"
      >
        <el-form-item label="Type de transaction " prop="type">
          <el-select
            style="width: 100%"
            v-model="transfert.type"
            placeholder="Choisir le type"
            clearable
            filterable
          >
            <el-option
              v-for="item in types"
              :key="item.name"
              :label="`${item.value}`"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- Client -->

        <!--         <el-form-item label="Compte créditeur client" prop="account_from">
          <el-select
            style="width: 100%"
            v-model="transfert.account_from"
            placeholder="Choisir le compte"
            clearable
            filterable
            :disabled="disable"
          >
            <el-option
              v-for="item in accountContact"
              :key="item.account_id"
              :label="`${item.account_name}`"
              :value="item.numero"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="Compte banque ou wallet" prop="account_to">
          <el-select
            style="width: 100%"
            v-model="transfert.account_to"
            placeholder="Choisir le compte"
            clearable
            filterable
          >
            <el-option
              v-for="item in accounts"
              :key="item.account_id"
              :label="`${item.account_name}`"
              :value="item.numero"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="amount" label="Montant">
          <el-input-number
            style="width: 100%"
            :controls="false"
            v-model="transfert.amount"
          >
          </el-input-number>
        </el-form-item>

        <el-form-item prop="describe" label="Raison de la transaction" class="flex-1">
          <el-input
            type="textarea"
            placeholder="Ecrire ici"
            v-model="transfert.describe"
            maxlength="60"
            show-word-limit
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button @click="handleCloseDialog">Annuler</el-button>
        <el-button
          type="primary"
          :loading="loadingOp"
          :disabled="transfert.amount <= 0"
          @click="createNewTransaction('refNewTransaction')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchAccounts,
  fetchAccountContact,
  newTransaction,
} from "@/api/account";
export default {
  name: "ACCOUNT-CREDIT",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    accountFrom: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      types: [
        {
          name: "APPROV_CUSTOMER",
          value: "Versement client",
        },
        {
          name: "WITHDRAW_CUSTOMER",
          value: "Remboursement client",
        },
        {
          name: "DEBT_CUSTOMER",
          value: "Créance au client",
        },
      ],
      loadingOp: false,
      labelPosition: "top",
      accounts: [],
      accountContact: [],
      transfert: {
        account_from: "",
        account_to: "",
        amount: 0,
        type: "APPROV_CUSTOMER",
        describe: "",
      },
      rule: {
        account_from: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
        account_to: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
        account_form: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "Le type est obligatoire",
            trigger: "change",
          },
        ],
        amount: [
          {
            required: true,
            message: "La montant est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getAccounts();
    this.getAccountContact();
  },
  methods: {
    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAccountContact() {
      await fetchAccountContact()
        .then((res) => {
          this.accountContact = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleTransaction() {
      console.log("open");
      this.resetTransfert();
      this.$nextTick(() => {
        this.$refs["refNewTransaction"].clearValidate();
      });
    },
    resetTransfert() {
      this.transfert = {
        account_from: "",
        account_to: "",
        amount: 0,
        type: "APPROV_CUSTOMER",
        describe: "",
      };
    },
    createNewTransaction(refNewTransaction) {
      this.$refs[refNewTransaction].validate((valid) => {
        if (valid) {
          this.loadingOp = true;
          console.log("account from " + this.accountFrom);
          this.transfert.account_from = this.accountFrom;
          newTransaction(this.transfert)
            .then(() => {
              setTimeout(() => {
                this.loadingOp = false;
                this.dialogVisibleTransfert = false;
                this.$message({
                  message: "Operation enregistrée.",
                  type: "success",
                });
                this.$emit("sucess", false);
              }, 1.5 * 1000);
            })
            .catch((err) => {
              setTimeout(() => {
                this.loadingOp = false;
                var messageError = "";
                if (err.data.status === 409 || err.data.status === 404) {
                  messageError = err.data.message;
                }
                if (err.data.status === 400) {
                  messageError = "Veuillez remplir le champs obligatoire";
                }
                if (err.data.status === 500) {
                  messageError = "Erreur Serveur";
                }
                this.$message({
                  message: messageError,
                  type: "error",
                });
              }, 1.5 * 1000);
            });
        }
      });
    },
    handleCloseDialog() {
      this.resetTransfert();
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
